<app-header [navbarBrandRouterLink]="['/dashboard']" [fixed]="true"
  [navbarBrandFull]="{src: 'assets/img/brand/logo-igreja.png', height: 40, alt: 'Igreja Logo'}"
  [navbarBrandMinimized]="{src: 'assets/img/brand/logo-igreja-min.png', width: 30, height: 30, alt: 'Igreja Logo'}"
  [sidebarToggler]="'lg'" [asideMenuToggler]="false" [mobileAsideMenuToggler]="false"
  [ngClass]="backgroundTitleCls">
  <ul class="nav navbar-nav d-md-down-none">
    <li class="nav-item px-3" *ngFor="let menuItem of headerNavItems">
      <a class="nav-link"
        [routerLink]="menuItem.url" routerLinkActive="router-link-active">
        {{ menuItem.name }}
      </a>
    </li>
  </ul>
  <ul class="nav navbar-nav ml-auto">
    <li class="nav-item dropdown" dropdown placement="bottom right">
      <a class="nav-link mr-2" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false"
        dropdownToggle (click)="false">
        <img src="assets/img/avatars/photo-user.png" class="img-avatar" [alt]="user.email" />
        <span class="d-none d-sm-none d-md-none d-lg-inline">{{ userName }}</span>
      </a>
      <div class="dropdown-menu dropdown-menu-right" *dropdownMenu aria-labelledby="simple-dropdown">
        <div class="dropdown-header text-center"><strong>Configurações</strong></div>
        <a class="dropdown-item" [routerLink]="['/configuracao', 'usuario', 'perfil']"><i class="fa fa-user"></i> Perfil</a>
        <a class="dropdown-item" [routerLink]="['/configuracao']"><i class="fa fa-wrench"></i> Configuração</a>
        <div class="divider"></div>
        <a class="dropdown-item" (click)="logout()"><i class="fa fa-lock"></i> Sair</a>
        <div class="divider"></div>
        <span class="dropdown-item text-center click" (click)="checkForUpdate()"><small>Versão: {{ appVersion }}</small></span>
      </div>
    </li>
  </ul>
</app-header>
<div class="app-body">
  <app-sidebar #appSidebar [fixed]="true" [display]="'lg'" [minimized]="sidebarMinimized"
    (minimizedChange)="toggleMinimize($event)">
    <app-sidebar-nav [navItems]="navItems" [perfectScrollbar] [disabled]="appSidebar.minimized"></app-sidebar-nav>
    <app-sidebar-minimizer></app-sidebar-minimizer>
  </app-sidebar>
  <!-- Main content -->
  <main class="main">
    <!-- Breadcrumb -->
    <!-- breaking change 'cui-breadcrumb' -->
    <cui-breadcrumb>
      <!-- Breadcrumb Menu-->
      <li class="breadcrumb-menu d-md-down-none">
        <div class="btn-group" role="group" aria-label="Button group with nested dropdown">
          <!-- <a class="btn" href="#"><i class="icon-speech"></i></a> -->
          <a class="btn" [routerLink]="['/dashboard']"><i class="icon-graph"></i> &nbsp;Dashboard</a>
          <a class="btn" [routerLink]="['/configuracao']"><i class="icon-settings"></i> &nbsp;Configuração</a>
        </div>
      </li>
    </cui-breadcrumb>

    <!-- container-fluid -->
    <div class="container-fluid pl-3 pr-3">
      <div class="loader-status" *ngIf="'default' | swIsLoading | async">
        <div class="loader-spinner"><div></div><div></div></div>

        <h6 class="loader-hint">
          <strong>Aguarde!</strong> Carregando...
        </h6>
      </div>

      <router-outlet></router-outlet>
    </div><!-- /.container-fluid -->
  </main>

</div>
<app-footer>
  <a class="unit btn btn-link" *ngIf="unitName && organizacaoLink" [routerLink]="['/organizations']">
    <i class="fas fa-university"></i> {{ unitName }}
  </a>
  <a class="unit btn btn-link" *ngIf="unitName && electron" [routerLink]="['/organizations']">
    <i class="fas fa-university"></i> {{ unitName }}
  </a>
  <span class="ml-auto d-none d-sm-none d-md-none d-lg-flex">
    <a *ngIf="!electron" target="_blank" href="http://www.laddertecnologia.com.br">Ladder Tecnologia </a>
    <span *ngIf="electron">Ladder Tecnologia</span>
    &nbsp; &copy; 2010 - {{ currentDate.getFullYear() }}
  </span>
</app-footer>
