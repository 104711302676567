import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/dashboard',
    icon: 'icon-speedometer',
  },
  // ---- CADASTROS ----
  {
    class: 'nav-title',
    name: 'Cadastros Base',
    url: '.',
    attributes: { permission: { namespace: 'people' } },
  },
  {
    name: 'Pessoas',
    url: '/cadastro/person',
    icon: 'fas fa-user-friends',
    attributes: { permission: { namespace: 'people', action: 'person_list' } },
  },
  // {
  //   name: 'Fiéis',
  //   url: '/cadastro/member',
  //   icon: 'fa fa-users',
  //   attributes: { permission: { namespace: 'associated', action: 'member_list' } },
  // },
  {
    name: 'Dizimistas',
    url: '/cadastro/dizimista',
    icon: 'fas fa-hand-holding-heart',
    attributes: { permission: { namespace: 'dizimo', action: 'dizimista_list' } },
  },
  {
    name: 'Diversos',
    icon: 'fas fa-bars',
    url: '.',
    children: [
      {
        name: 'Categorias',
        url: '/cadastro/categorias',
        icon: 'far fa-circle',
        attributes: { permission: { namespace: 'people', action: 'category_list' } },
      },
      {
        name: 'Comunidade',
        url: '/cadastro/community',
        icon: 'far fa-circle',
        attributes: { permission: { namespace: 'people', action: 'community_list' } },
      },
      {
        name: 'Parentesco',
        url: '/cadastro/kinship',
        icon: 'far fa-circle',
        attributes: { permission: { namespace: 'people', action: 'kinship_list' } },
      },
    ]
  },
  // ---- Dízimo ----
  {
    class: 'nav-title',
    name: 'Dízimo',
    url: '/dizimo/dashboard',
    attributes: { permission: { namespace: 'dizimo' } },
  },
  {
    name: 'Apuração',
    url: '/dizimo/apuracao',
    icon: 'far fa-money-bill-alt',
    attributes: { permission: { namespace: 'dizimo', action: 'apuracao_list' } },
  },
  // ---- AGENDA ----
  {
    class: 'nav-title',
    name: 'AGENDA',
    url: '.',
    attributes: { permission: { namespace: 'schedule' } },
  },
  {
    name: 'Agendas',
    icon: 'far fa-calendar',
    url: '/schedule/schedule',
    attributes: { permission: { namespace: 'schedule', action: 'schedule_list' } },
  },
  // ---- Financeiro ----
  {
    class: 'nav-title',
    name: 'Financeiro',
    url: '.',
    attributes: { permission: { namespace: 'finance' } },
  },
  {
    name: 'Banco / Caixa',
    url: '/finance/lancamento',
    icon: 'fas fa-wallet',
    attributes: {
      permission: { namespace: 'finance', action: 'lancamento_list' },
      headerNav: true
    },
  },
  {
    name: 'Contas a Pagar',
    url: '/finance/conta-pagar',
    icon: 'fas fa-money-bill-wave',
    attributes: { permission: { namespace: 'finance', action: 'conta_pagar_list' } },
  },
  {
    name: 'Cheques',
    icon: 'fas fa-money-check-alt',
    url: '/finance/cheque/',
    attributes: { permission: { namespace: 'finance', action: 'cheque' } },
    children: [
      {
        name: 'Emitidos',
        url: '/finance/cheque/emitido/',
        icon: 'fas fa-money-check-alt',
        attributes: { permission: { namespace: 'finance', action: 'cheque_emitido_list' } },
      },
      {
        name: 'Recebidos',
        url: '/finance/cheque/recebido/',
        icon: 'fas fa-money-check-alt',
        attributes: { permission: { namespace: 'finance', action: 'cheque_recebido_list' } },
      },
    ]
  },
  {
    name: 'Diversos',
    icon: 'fas fa-bars',
    url: '.',
    children: [
      {
        name: 'Banco / Caixa',
        icon: 'fas fa-wallet',
        url: '.',
        children: [
          {
            name: 'Conta',
            url: '/finance/conta',
            attributes: { permission: { namespace: 'finance', action: 'conta_list' } },
          },
          {
            name: 'Tipo Conta',
            url: '/finance/tipo-conta',
            attributes: { permission: { namespace: 'finance', action: 'conta_tipo_list' } },
          },
        ]
      },
      {
        name: 'Categorias',
        icon: 'fas fa-layer-group',
        url: '.',
        children: [
          {
            name: 'Entradas',
            url: '/finance/categoria-entrada',
            icon: 'fas fa-arrow-down',
            attributes: { permission: { namespace: 'finance', action: 'categoria_entrada_list' } },
          },
          {
            name: 'Saídas',
            url: '/finance/categoria-saida',
            icon: 'fas fa-arrow-up',
            attributes: { permission: { namespace: 'finance', action: 'categoria_saida_list' } },
          },
        ]
      },
      {
        name: 'Formas',
        icon: 'far fa-handshake',
        url: '.',
        children: [
          {
            name: 'Pagamento',
            url: '/finance/forma-pagamento',
            icon: 'fas fa-arrow-up',
            attributes: { permission: { namespace: 'finance', action: 'forma_pagamento_list' } },
          },
          {
            name: 'Recebimento',
            url: '/finance/forma-recebimento',
            icon: 'fas fa-arrow-down',
            attributes: { permission: { namespace: 'finance', action: 'forma_recebimento_list' } },
          },
        ]
      },
    ]
  },
  // ---- Relatórios ----
  {
    class: 'nav-title',
    name: 'Relatórios',
    url: '.',
  },
  {
    name: 'Financeiro',
    url: '/finance/report',
    icon: 'fas fa-print',
    attributes: { permission: { namespace: 'finance' } },
  },
  {
    name: 'Pessoas',
    url: '/cadastro/report',
    icon: 'fas fa-print',
    attributes: { permission: { namespace: 'people' } },
  },
];
