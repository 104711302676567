import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { EIgrejaModule } from '../app/apps/eigreja/eigreja.module';
import { environment } from '../environments/environment';

if (environment.production) {
  enableProdMode();
}

document.title = 'E-igreja';

window.addEventListener('dragover', event => event.preventDefault(), false);
window.addEventListener('drop', event => event.preventDefault(), false);

platformBrowserDynamic().bootstrapModule(EIgrejaModule);
